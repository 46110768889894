<template>
  <v-container class="pa-0 ma-0" fluid>
    <div class="d-flex" v-if="dataProvider && !loading">
      <div style="width: 50%">
        <!-- <v-card-title>Data Provider:</v-card-title> -->
        <v-list-item>
          <v-list-item-content>
            <v-container fluid>
              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("id") }}:</b>
                </v-col>
                <v-col>{{ dataProvider.dataProviderId }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("name") }}:</b>
                </v-col>
                <v-col>{{ dataProvider.name }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("type") }}:</b>
                </v-col>
                <v-col>{{ dataProvider.type }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("url") }}:</b>
                </v-col>
                <v-col>{{ dataProvider.url }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("timeout") }}:</b>
                </v-col>
                <v-col>{{ dataProvider.timeout }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("checkPointPattern") }}:</b>
                </v-col>
                <v-col>{{ dataProvider.checkPointPattern }}</v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div style="width: 50%">
        <!-- <v-card-title>&nbsp;</v-card-title> -->
        <v-list-item>
          <v-list-item-content>
            <v-container fluid>
              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("parser") }}:</b>
                </v-col>
                <v-col>{{ dataProvider.parser }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("mapper") }}:</b>
                </v-col>
                <v-col>{{ dataProvider.mapper }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("createdAt") }}:</b>
                </v-col>
                <v-col>{{ dataProvider.createdAt | getDisplayDate }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("updatedAt") }}:</b>
                </v-col>
                <v-col>{{ dataProvider.updatedAt | getDisplayDate }}</v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
        </v-list-item>
        <v-card-title>Attributes:</v-card-title>
        <GsAttributesDisplay
          v-if="dataProvider"
          :entityId="dataProvider.dataProviderId"
          entityName="data-providers"
          collection="dataProvider"
        />
        <v-card-title>Tags:</v-card-title>
        <GsTagsDisplay
          v-if="dataProvider"
          :id="dataProvider.dataProviderId"
          endpoint="/data-providers"
          collection="dataProvider"
          tagType="dp"
        />
      </div>
    </div>
    <div v-if="!dataProvider && !loading">
      <span>{{ $t("no_data") }}</span>
    </div>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
        class="ma-4"
      ></v-progress-circular>
      <span>{{ $t("loading") }}</span>
    </div>
  </v-container>
</template>

<script>
import GsAttributesDisplay from "@/components/AttributeEditor/GsAttributesDisplay";
import GsTagsDisplay from "@/components/AttributeEditor/GsTagsDisplay";

export default {
  name: "GsDataProviderDetails",
  components: {
    GsAttributesDisplay,
    GsTagsDisplay
  },
  props: {
    dataProvider: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // scopes: [],
      loading: false
    };
  },
  created: async function() {},
  watch: {},
  methods: {}
};
</script>
