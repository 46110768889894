<template>
  <v-card flat>
    <div
      class="d-flex flex-column justify-center align-center pa-4"
      v-if="isLoading"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        class="ma-4"
      ></v-progress-circular>
      <span>{{ $t("loading") }}</span>
    </div>
    <v-data-table
      v-if="!isLoading"
      :headers="headers"
      :items="items"
      item-key="name"
      hide-default-footer
    >
    </v-data-table>
  </v-card>
</template>

<script>
import { GsDataTable, GsDataFilter } from "ngt-frontend-core";
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsAttributesDisplay",
  components: {
    GsDataTable
  },
  props: {
    entityId: {
      type: String,
      default: null
    },
    entityName: {
      type: String,
      default: null
    },
    collection: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      appSettingKey: "resourceServerDetailsAttrEditor",
      fullEndpoint: "",
      search: "",
      dialogEditorVisible: false,
      selectedLabelId: null,
      hasChange: false,
      isLoading: false
    };
  },
  computed: {
    loadParams() {
      return {
        entityId: this.entityId,
        entityName: this.entityName,
        collection: this.collection
      };
    },
    headers() {
      return [
        {
          value: "name",
          text: this.$t("name"),
          align: "left",
          sortable: false,
          width: "40%"
        },
        {
          value: "value",
          text: this.$t("value"),
          align: "left",
          sortable: false,
          width: "60%"
        }
      ];
    },
    items() {
      if (this.$store.state.entityAttrs.items.length === 0) return [];
      return this.$store.state.entityAttrs.items;
    },
    save_disabled() {
      return !this.hasChange;
    }
  },
  watch: {
    loadParams: {
      deep: true,
      immediate: true,
      async handler(v) {
        await this.loadData();
      }
    }
  },
  async created() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("entityAttrs/load", this.loadParams);
      } catch (error) {
        this.error = "Error";
      } finally {
        this.isLoading = false;
      }
    },
    item_change(item) {
      this.hasChange = true;
    }
  }
};
</script>
