<template>
  <div>
    <div class="text-left my-4" v-if="isLoading">
      <v-progress-circular
        :size="20"
        :width="3"
        indeterminate
        color="grey"
        class="mr-4"
      ></v-progress-circular>
      <span>{{ $t("loading") }}</span>
    </div>
    <div v-if="!isLoading">
      <v-chip
        v-for="(item, index) in tags"
        :key="index"
        class="ma-2"
        color="default"
        label
      >
        {{ item }}
      </v-chip>
    </div>
  </div>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsTagsDisplay",
  props: {
    id: {
      type: String,
      default: null
    },
    endpoint: {
      type: String,
      default: null
    },
    collection: {
      type: String,
      default: null
    },
    tagType: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      tags: [],
      appSettingKey: "resourceServerDetailsAttrEditor",
      search: "",
      dialogEditorVisible: false,
      allTagNames: [],
      isLoading: false
    };
  },
  computed: {
    fullEndpoint() {
      return `${this.endpoint}/${this.id}/tags`;
    },
    headers() {
      return [
        { text: this.$t("name"), align: "left", sortable: true, value: "name" },
        {
          text: this.$t("value"),
          align: "left",
          sortable: true,
          value: "value"
        },
        { text: "", sortable: false, value: "actions" }
      ];
    },
    filteredTagNames() {
      if (!Array.isArray(this.allTagNames)) return [];
      if (typeof this.tagType !== "string") return [];
      let result =
        this.tagType !== "*"
          ? this.allTagNames
          : this.allTagNames.filter(p => p.startsWith(`${this.tagType}:`));
      result = result.filter(p => !this.tags.includes(p));
      result = this.arrSortAlphabetically(result);
      return result;
    }
  },
  watch: {
    fullEndpoint: {
      deep: true,
      immediate: true,
      async handler(v) {
        this.getTags();
      }
    }
  },
  created() {
    this.getAllTagNames();
    this.getTags();
  },
  methods: {
    async getTags() {
      try {
        this.isLoading = true;
        const response = await callAPI({
          url: this.fullEndpoint,
          method: "GET"
        });
        this.tags = this.arrSortAlphabetically(response.data);
      } catch (err) {
        console.log(err);
        this.validationResult = err;
      } finally {
        this.isLoading = false;
      }
    },
    async getAllTagNames() {
      const result = await callAPI({
        url:
          this.$store.state.idp_api_url +
          `/_abac/tag-collection/${this.collection}`,
        method: "GET"
      });
      this.allTagNames = result.data;
    },
    getDisplayText(item) {
      if (typeof this.tagType !== "string") return item;
      return this.removeStringIfStartsWith(item, this.tagType + ":");
    },
    removeStringIfStartsWith(originalString, stringToRemove) {
      if (originalString.startsWith(stringToRemove)) {
        return originalString.slice(stringToRemove.length);
      } else {
        return originalString;
      }
    }
  }
};
</script>
